.offersContent{
    margin:0;
    padding:0;
    box-sizing:border-box;
    width:100%;
    
}

.offersHeaderSectionWrapper{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    margin-left:4rem;
}

.offersHeaderSectionWrapper .contactData{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:100%;
    height: 100%;
   
}

.offersHeaderSection{
    
    display:flex;
    flex-direction: column;
    
    margin-bottom: 4rem;
}

.offersHeader h2{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

.offersHeaderSection .greyUnderline{
margin:0;
padding:0;
margin-top: .5rem;
width: 26rem;
}

.offersHeaderSection .orangeUnderline{

width:8rem;
}

.offersCardSectionWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

.offersCardSection{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin-bottom: 4rem;
    

}

.offersCard{
    width:370px;
    height: 405px;
    max-width: 100vw;
    border: 1.5px solid #878787;
    text-align: center;
    margin-bottom:3rem; 
      
    
}

.offersCardInfosWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

.offersCardInfos{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
    margin: 0;
    padding: 0;
}

.offersCardInfos h4{
   
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 18px;
   color:#575656;
   font-weight: lighter;
   
}

.offersCardInfos h4 span{
    font-weight: bold;
}

.offersCardTitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color:#575656;
    line-height: 2.5rem;
 }
.offersCardSubTitle{
    color: #fbb24c;
    font-size: 30px;
    font-weight: bold;
    margin-top: 0;
    line-height: 2.5rem;
}

 .offersCardContent{
    
    max-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;  
    
      
 }

.offersCardContent p{
    font-size: 10px;
    color: #878787;
    padding: 0;
    margin: 0;
}

 .offersCardButtonSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    
 }

 .offersCardLowerSection{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
 }

 .offersButton{
    cursor: pointer;
    margin:0;
    margin-bottom: 0;
    width: 142px;
    color:#878787;
    border: 1px solid #878787;
    border-radius: 35px;
    padding:1rem;
    background-color: #fff;
    font-size: 18px;
    font-weight: lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }

 .offersButton:hover{
    border:1px solid #fbb24c;
    color:#575656;
}

.offersButton:hover .offersIcon{
    translate: +10px;
    transition: .5s ease;
}

.offersLoadingComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 405px;
}


@media screen and (max-width:768px) {

    .offersContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .offersHeaderSectionWrapper{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        margin-left:0;
    }

    .offersHeaderSectionWrapper .contactData{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        width:100%;
        
       
    }
    .offersHeader{
        display: flex;
        justify-content: center;
    }

    .offersHeader h2{
        font-size: 48px;
    }

    .offersHeaderSection .greyUnderline{
        
        margin:0;
        padding:0;
        width:90%;
        margin-top: .5rem;
        margin-left: 5%;
        
        }
        
        .offersHeaderSection .orangeUnderline{
        max-width: 100%;
        margin: 0;
        width: 70%;
        margin-left: 15%
        }
    
}


@media screen and (max-width:1080px) {

    .offersHeaderSectionWrapper{
        flex-wrap: wrap;
    }

  /** .offersHeaderSectionWrapper .contactData .contactItem{
   }

    .offersHeaderSectionWrapper .contactData {
       
    } **/    
}