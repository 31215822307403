

.content {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    }

.cardWrapper{
  display: flex;
  justify-content: center;
}
.contactHeader {
    
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    width: 80vw;
    margin-left: 5vw;
}
.contactHeader .greyUnderline{
    margin: 0;
    margin-top: .5rem;
    width: 54rem;
}

.contactHeader .orangeUnderline{
    width:35rem;
    margin: 0;
}

.contactHeaderTitle{
 text-transform: uppercase;
}

.contactHeaderTitle h2{
    color: #575656;
    padding: 0;
    margin: 0;
}


.contactCard{

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 80vw;
    margin-top: 2rem;
    margin-bottom: 10vh;

}

.form{
    display:flex;
    flex-direction: column;
    width:55%;
}

/*delete all default focus settings*/
.form input:focus,
select:focus,
textarea:focus,
button:focus {
    outline:none;
}

.form textarea,
.form input{
    border: none;
    border-bottom: 1.5px solid #ffbd5c;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 32px;
    padding:.5rem;
    resize: vertical;
    color:#575656;
    font-weight: lighter;
}


.form textarea:focus,
.form input:focus{

    border-bottom: 1.5px solid #575656;
}

 .formbutton {
    cursor: pointer;
    margin:0;
    
    height: 77px;
    width: 255px;
    min-width: 255px;
    color:#878787;
    border: 1px solid #878787;
    border-radius: 35px;
    padding:1rem;
    background-color: #fff;
    font-size: 32px;
    font-weight: lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.formbutton:hover{
    border:1px solid #fbb24c;
    color:#575656;
}

.formbutton:hover .formIcon{
    translate: +10px;
    transition: .5s ease;
}

.formLowerSection{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.form input[type=checkbox]{
    width:2rem;
    height: 2rem;
    
}
.form label{
    color:#575656;
    font-size: 24px;
    text-align: left;
    font-weight: lighter;
}

.SUBJECTInput{
    background-color: #d4d1d1;

}
.contactCard .contactDataWrapper{
 width: 30%;
}
.contactData{
    display:flex;
    flex-direction: column;
    width: 100%;
    
}

.contactIcons{
    color:#575656;
    font-size: 24px;
    font-weight: lighter;
    border:1px solid #878778;
    border-radius: 50%;
    padding: 1.5rem;
    margin-right:2rem;
}

.contactItem{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.contactItem a{
    color:#575656;
    text-decoration: none;
}

.contactItem a:hover{
    text-decoration: underline;
}

.contactItem p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    color:#575656;
    font-weight: lighter;
}

.contactFormLink{
 color:#878778;
}
.contactFormLink:hover{
    color:#fbb24c;
}

@media screen and (max-width:768px) {


    .content{
        max-width: 100%;
    }

    .contactHeader{ 
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .contactHeaderTitle{
        display:flex;
        width:100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contactHeaderTitle .greyUnderline {
        max-width: 90%;

    }

    .contactHeaderTitle .orangeUnderline {
        max-width: 50%;        
    }

    .contactHeaderTitle h2{
        color: #575656;
        padding: 0;
        margin: 0;
        font-size: 48px;
    }

    .cardWrapper{
        display: flex;
        width:100%;
        justify-content: center;
        align-items: center;
    }

    .contactCard {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
       
    }
    .contactCard .contactDataWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .contactCard .contactData{width: 90%;}

    .form{
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .formLowerSection {
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .contactData{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    .form textarea,
    .form input{
    border: none;
    border-bottom: 1.5px solid #fbb24c;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    padding:.5rem;
    resize: vertical;
    color:#575656;
    font-weight: lighter;
}
    
}

@media screen and (max-width:1080px) {

    .contactCard {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
       
    }
  .contactData{
    width: 50%;
  }

  .contactCard .formLowerSection{
    flex-direction: column;
  }
     
    
}