.detailsContent{
    margin:0;
    padding:0;
    box-sizing:border-box;
    width:100%;
    
}

.detailsHeaderSectionWrapper{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    margin-left:4rem;
}

.detailsHeaderSectionWrapper .contactData{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:100%;
    height: 100%;
   
}

.detailsHeaderSection{
    
    display:flex;
    flex-direction: column;
    
    margin-bottom: 4rem;
}

.detailsHeader h2{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

.detailsSubtitle {
    font-size: 48px;
    padding: 0;
    margin: 0;
}

.detailsHeaderSection .greyUnderline{
margin:0;
padding:0;
margin-top: .5rem;
width: 26rem;
}

.detailsHeaderSection .orangeUnderline{

width:8rem;
}

.detailsCardSectionWrapper{
    width: 100%;
    display: flex;
    
}

.detailsDescriptionSection h1{
    color:#575656;
    margin: 0;
 }

.detailsCardSection{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 4rem;
    

}

.detailsMore {
    margin: 0;
    padding: 0;
    width: 90%;
    max-width:100%;
    text-align: left;
}

.detailsMore h2{
    font-size: 32px;
}

.detailsMore p{
    text-align: justify; 
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #575656;            
            }

.detailsCard{
    padding: 0;
    margin: 0;
    width:555px;
    height: 500px;
    max-width: 100vw;
    border: 1.5px solid #878787;
    text-align: center;
     
      
    
}


.detailsDescriptionSection{
    padding-left: 2rem; 
    margin: 0;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}

.detailsCardInfosWrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.detailsCardInfos{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
}

.detailsCardInfos h4{
   
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: 18px;
   color:#575656;
   font-weight: lighter;
   
}

.detailsCardInfos h4 span{
    font-weight: bold;
}

.detailsCardTitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color:#575656;
    line-height: 2.5rem;
 }
.detailsCardSubTitle{
    color: #fbb24c;
    font-size: 30px;
    font-weight: bold;
    margin-top: 0;
    line-height: 2.5rem;
}

 .detailsCardContent{
    height: 100%;
    max-height: 100%;
    display: flex;
    
    flex-direction: column;
    justify-content: space-between; 

    
      
 }

 .detailsCardButtonSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
 }

 .detailsKontaktButton{
    cursor: pointer;
    margin:0;
    margin-bottom: 0;
    width:300px;
    color:#878787;
    border: 1px solid #878787;
    border-radius: 35px;
    padding:1rem;
    vertical-align: middle;
    background-color: #fff;
    font-size: 32px;
    font-weight: lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }

 
 .detailsKontaktButton .detailsKontaktIcon {
    padding-bottom: .5rem;
 }

 .detailsKontaktButton:hover{
    border:1px solid #fbb24c;
    color:#575656;
}

.detailsKontaktButton:hover .detailsKontaktIcon{
    translate: +10px;
    transition: .5s ease;
}

.detailsDescriptionSection p {
    font-size: 18px;
    color:#575656;
    text-align: justify;
}



@media screen and (max-width:768px) {

    .detailsContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .detailsHeaderSectionWrapper{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        margin-left:0;
    }

    .detailsHeaderSectionWrapper .contactData{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        width:100%;
        
       
    }

    .detailsHeaderSection{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .detailsHeader {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .detailsHeader h2{
        font-size: 48px;
    }

    .detailsHeaderSection .greyUnderline{
        max-width:70%;
        margin:0;
        padding:0;
        margin-top: .5rem;
        height: 8px;
        
        }
        
        .detailsHeaderSection .orangeUnderline{
        max-width: 60%;
        margin-top: .5rem;
        width: 26rem;
        padding: 0;
        margin: 0;
        }

    .detailsCardSectionWrapper{
        display: flex;
        flex-direction: column;

    }
    
    .detailsCardSection{
    margin: 0;
    padding:0;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;}

    .detailsCard{
        max-width: 90%;
        max-height: 60vh;}

    .detailsCardButtonSection{max-width: 100%;}
    
    .detailsCardInfos{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .detailsKontaktButton {
        max-width: 100%;
        width: 100%;
        font-size: 36px;
    }

    .detailsCardInfosWrapper {
        width: 100%;
    }

    .detailsCardInfos{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .detailsDescriptionSection {
        padding-top: 5%;
        padding-left:5%;
        width: 90%;
    }
    .detailsDescriptionSection h1{ font-size: 24px;}

    .detailsMore {
        width: 90%;
        text-align:justify;
    }

}
