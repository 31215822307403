@font-face {
    font-family: 'MrDafoe-Regular';
    src: url(../fonts/MrDafoe-Regular.ttf) format('truetype');
}



.Content{
    display: block;
  
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 3rem;
    opacity: 1;
    height:70vh;
    position: absolute;
    left: 0;
    top:20vh;
    overflow: hidden;
    z-index: 0;
}

.homeheaderimg{
    
    
}


.SOLIDUS{
    font-family: 'MrDafoe-Regular';
    font-size: 200px;
    color:#575656;
    margin-top: -2rem;
    margin-left:1rem;
}

.UNDStyle{
     color:#878787;
     font-size:86px;
      opacity:.7;
      margin-top:.7rem;
}

.HomeTitleSection {
    
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    width: 100%;
    height: 100%;
    line-height:6rem;
}

.HomeTitleRow1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color:#878787;
    font-size: 72px;
    padding-left: 5rem;

}

.HomeTitleRow2 {
    display: flex;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: #fbb24c;
    font-size: 120px;
    padding-left: 4rem;
}

.HomeTitleRow3 {
    display: flex;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color:#575656;
    font-size: 96px;
}

.HomeTitleButtonSection {
    width: 1035.5px;
    display: flex;
    justify-content: space-between;

    
}

/* vielleicht */
.HomeButton {
    cursor: pointer;
    margin:0;
    margin-bottom: 3rem;
    width: 395px;
    color:#878787;
    border: 1.5px solid #878787;
    border-radius: 35px;
    padding:1rem;
    background-color: rgba(0, 0, 0, 0);
    font-size: 32px;
    font-weight: lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.HomeButton:hover{
    border:1px solid #fbb24c;
    color:#575656;
}

.HomeButton:hover .serviceIcon{
    translate: +10px;
    transition: .5s ease;
}

.HomeIcon{
    margin-bottom: -.25rem;
    
} 

@media screen and (max-width: 1728px) {
    .SOLIDUS{
        margin: 0;
        font-size: 146px;
    }
}

@media screen and (max-width:1376px){
    .SOLIDUS{
        margin: 0;
        font-size: 112px;
    }
    .UNDStyle{
        margin: 0;
        font-size: 72px;
    }
    .HomeTitleRow1{
        font-size: 72px;
    }
    .HomeTitleRow2{
        font-size:68px;
    }
    .HomeTitleRow3{
        font-size: 64px;
    }
}

@media screen and (max-width:768px) {
    
    .Content{height: 90%;
             padding-top: 0; 
             display: flex;
             top:15%;
                         
    }

    .HomeHeaderStyle{
        height: 100%;
        margin-bottom: 2rem;
        
    }

    .SOLIDUS{
        font-size: 96px;
    }

    .UNDStyle{
        font-size: 48px;
        
    }

    .HomeTitleRow1{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding:0;
        margin: 0;
        font-size: 48px;
    }
    .HomeTitleRow2{
        width: 100%;
        font-size:42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        line-height: 2rem;
    }
    
    .HomeTitleRow3{
        font-size:24px;
        line-height: 2rem;
    }
    
    .HomeTitleButtonSection {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        
        line-height: 2rem;
        
    } 

    .HomeButton{
        width: 90vw;
        font-size: 24px;
    }

}