.Footer {
    
    width: 100%;
    
    height:10vh;
    background-color: #878787;
    opacity: 100%;
    z-index: 99;
}

.greyBox {
 height: 6px;
 background-color: #575656;
}

.orangeBox {
 height: 6px;
 background-color: #fbb24c;
}

.footerContent {
 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    
    
  
}

.footerLink {
    text-decoration: none;
    color: #fff;

}

.footerLink:hover{
    color:#fbb24c;
}

@media screen and (max-width: 768px){

    .Footer{
        margin:0;
    }
    
}