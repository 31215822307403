.impressumContent {
    width: 100%;
    display:flex;
    justify-content: center;
}
.impressumContent h3,h4{
    padding: 0;
    margin: 0;
    font-weight: bold;
}
.impressumSection{
    width:75%;
    display: flex;
    flex-direction: column;
}

.impressum {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    color: #575656;
}

.impressum .impressumPart{
    margin-top:2rem;
    margin-bottom: 2rem;
    
}

 .impressum .impressumHaftung p{
    line-height: normal;
}


.impressum p{

    line-height: .5rem;
}

@media screen and (max-width:768px) {
    .impressum .impressumPart p{
       line-height: normal;
    }
    
}