
.App {
  box-sizing:border-box;
  margin: 0;
 /* maybe auch hier schon den view begranzen ?*/
}

.navigation{

  display:flex;
  justify-content: space-between;
  
  width: 90%;
  height: 10vh;
  margin-bottom: 1rem;
  padding-top:2.5vh;
  text-decoration: none;
  font-family: 'Roboto',sans-serif;
  padding-bottom: 1rem;
  
}

.active{
  color: #fbb24c !important;
}
.active::after{
 

}

.navlogo{
  margin-left: 0;
  width:10vw;
  
}

.navlogo img{

  height: 70%;
  
}

.navmenu{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navmenu .navitems{
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navlinks{

  padding:3rem 0 0 0;
  position: relative;
  text-decoration: none;
  color: #575656;
  cursor: pointer;
}

.navlinks:hover {
  color:#878787;
  
}

.navlinks::before{

  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  bottom: 1rem;
  left: 0;
  background-color: #fbb24c;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navlinks:hover::before{
  transform:scaleX(1);
}

.navlinks img{

  max-height: 7.5vh;

}

.FaBars{
  display: none;
  font-size: 24px;
  color: #575656;
  
}

.navlogo img{
  padding-left: 1rem;
}

@media screen and(max-height:980px) {
  .navlinks::before{
    bottom : 0rem;
  }  
}

@media screen and (max-width:1800px){
  .navlinks::before{
    bottom : 0rem;
  }
}

@media screen and (max-width:768px){

  .App{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .navigation{
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    height: 10vh;
    width: 100%;
   
    padding-top: 1rem;
    
  }
  .navlinks::before{
    bottom: 0rem;
  }


  .navlogo {
    margin: 0 0 0 0;
    padding: 0;
    height: 100%;
    width:33%;
    padding-left: 1rem;
  }
  .navlogo img{
    max-width:270px;
    max-height:57px;
    padding:0;

  }
  
  .FaBars{
      
      font-size: 24px;
      color: #575656;
      display: block;
      font-weight: lighter;
      padding-right: 1rem;
  }
  .navmenu{
    display: none;
    width: 100vw;
  }

  

  .navmenu.expanded {
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    
    width: 100vw;
    height: 10vh;
    background-color: #fff;
    z-index: 99;
  }

  .navmenu.expanded .navitems{
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    opacity: 1;
    
    
  }


  
}