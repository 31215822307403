.aboutcontent{

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    

}

.aboutdescriptionSection{

    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.aboutdescription{
    width: 70%;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
    color:#575656;
  

}
.aboutdescription p{
    text-align: justify;
}



.aboutHeaderSection{
    width: 80vw;
    margin-left: 5vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}



.aboutTitle{
    
    
    
    line-height: 1rem;
    text-transform: uppercase;

}

h2 {
 
    font-size: 76px;
    color:#fbb24c;

}

h3{

    font-size: 24px;
    font-weight:lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #575656;
    text-decoration: #fbb24c underline;
   
}

.orangeUnderline{
width: 800px;    
display:block;
height: 8px;
background-color: #fbb24c;

}

.greyUnderline{
    width:400px;
    display: block;
    height: 8px;
    background-color: #575656;
    margin-left: 57vh;
    margin-top: .75rem;
}

.abouticons {
    font-size: 32px;
    font-weight: lighter;
    border:1px solid #fbb24c;
    border-radius: 50%;
    padding: 2rem;
    
}

.servicebutton {
    cursor: pointer;
    margin:0;
    margin-bottom: 3rem;
    width: 395px;
    color:#878787;
    border: 1px solid #878787;
    border-radius: 35px;
    padding:1rem;
    background-color: #fff;
    font-size: 32px;
    font-weight: lighter;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.servicebutton:hover{
    border:1px solid #fbb24c;
    color:#575656;
}

.servicebutton:hover .serviceIcon{
    translate: +10px;
    transition: .5s ease;
}

.serviceIcon{
    margin-bottom: -.25rem;
    
}  


.serviceSection{
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.serviceCard{

    width: 30%;
    text-align: center;
    padding: 2rem;
    display: flex; /* to old style: delete all flex*/
    flex-direction: column;
    align-items: center;
}

.serviceCard p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 26px;
    color:#575656;
    font-weight: lighter;
    text-align: justify; /*to old style: center */
    width: 80%; /* to old style: weg damit */
}


@media screen and (max-width:1728px) {
    .aboutTitle h2{
        font-size: 64px;
    }

    
}

@media screen and (max-width:1376px) {

    .aboutHeaderSection{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    

}

@media screen and (max-width:1024px) {
    .aboutTitle h2{
        font-size: xx-large;
    }

    .orangeUnderline{
        margin: 0;
        padding: 0;
        max-width: 100%;}
    .greyUnderline{
        margin: 0;
        max-width: 75%;}
}

@media screen and (max-width:768px) {

    
    .aboutHeaderSection{
        margin:0;
        display: flex;
        flex-direction: column;
        width: 100%;
        
    }

    .aboutdescription{
        width: 100%;
        text-align: justify;
        margin-left: 1.25rem;
        margin-right:1.25rem;
    }

    
    .serviceSection{display: block;}
    .serviceCard {
        width: 100%;
        padding:0;
    }

    .serviceButtonSection{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .servicebutton{
        max-width: 90vw;
    }

    .orangeUnderline{
        width: 90%;
        margin-left: 5%;

    }
    .greyUnderline{
        width: 50%;
        margin:0;
        margin-left: 25%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .aboutTitle{
        line-height: normal;
        text-align: center;
        
    }
    .aboutTitle h2{
        padding:0;
        margin:0;
        font-size: 48px;
    }

    .aboutdescription{
        text-align: justify;
    }
}